.form-control .search-bar {
    
    height: unset;
    line-height: 3;
    
}

.container {
    width: auto;
    max-width: 680px;
    padding: 0 15px;
  }

  .bg-warning{
    background-image:linear-gradient( 
        131deg, #6c757d 50%, #ecbe45 100%)
  }